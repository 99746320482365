const Separator = '/';

//Page name only
export const Page = {
  // Authorization
  Login: 'login',
  Register: 'register',
  Awsauth: 'Awsauth',
  AccountCreated: 'AccountCreated',
  Forget: 'forget',

  // Dashboard
  Dashboard: 'dashboard',
  
  // Pages
  Analytics: 'analytics',
  Voters: 'search-voters-postgres',
  SearchUserClickHouse: 'search-voters-clickhouse',
  UsersManagement: 'users-management',
  VotersListing: 'voters-listing'
};





//Full url of each Page
export const AmacUrl = {
  // Authorization
  Login: Separator + Page.Login,

  // Dashboard
  Dashboard: Separator + Page.Dashboard,

  //Pages
  Analytics: Separator + Page.Analytics,
  UsersManagement: Separator + Page.UsersManagement,
  VotersListing: Separator + Page.VotersListing,
  Voters: Separator + Page.Voters,
  SearchUserClickHouse: Separator + Page.SearchUserClickHouse,
  // MyCases: Separator + [Page.Dashboard, Page.MyCases].join(Separator),

};